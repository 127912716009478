import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CurrentTopic from "../components/CurrentTopic";
import Calendar from "../components/Calendar";
import reklamaLV from "../assets/images/home-advert-lv.jpg";
import reklamaSmallLV from "../assets/images/home-advert-mobile-lv.jpg";
import reklamaEN from "../assets/images/home-advert-en.jpg";
import reklamaSmallEN from "../assets/images/home-advert-mobile-en.jpg";
import reklamaRU from "../assets/images/home-advert-ru.jpg";
import reklamaSmallRU from "../assets/images/home-advert-mobile-ru.jpg";
import PPLadvert from "../assets/images/PPL.png";
//import sertificationImage from "/images/sertification-small.jpg";
//import servicesImage from "/images/services-small.jpg";
import { LanguageContext } from "../App";
import linkNames from "../utils/linkNames";

const advertisementImages = {
  en: {
    large: reklamaEN,
    small: reklamaSmallEN,
  },
  lv: {
    large: reklamaLV,
    small: reklamaSmallLV,
  },
  ru: {
    large: reklamaRU,
    small: reklamaSmallRU,
  },
};

const Home: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const links = linkNames[language];

  const advertImages = advertisementImages[language] || advertisementImages.lv;
console.log(`links ${language}/${links.courses}`)
  return (
    <div className="home">
      
      <div className="current-advert">
        <Link className="home-link" to={`/${language}/${links.courses}`}>
        <picture>
            {/* Source for large screens */}
            <source media="(min-width: 1200px)" srcSet={advertImages.large} />
            {/* Source for small to medium screens */}
            <source media="(max-width: 767px)" srcSet={advertImages.small} />
            {/* Fallback image */}
            <img
              className="current-advert-img"
              src={advertImages.large} // Default image for fallback
              alt="Current advertisement"
            />
          </picture>
        </Link>
      </div>
      <div className="home-container">
        <div className="home-item" id="item1">
        <Link className="home-link" to={`/${language}/${links.certification}`}><CurrentTopic
            dbName="sertification.json"
            type="sertification"
            image={"sertification-small.jpg"}
          /></Link>
        </div>
        <div className="home-item" id="item2">
          <Calendar />
        </div>

        <div className="home-item" id="item3">
        <Link className="home-link" to={`/${language}/${links.about}/${links.services}`}><CurrentTopic
            dbName="about.json"
            type="services"
            image={"services-small.jpg"}
          /></Link>
        </div>
        <div className="home-item" id="item4">
          <div className="home-banner-container">
          <Link className="home-link" to={`/${language}/ppl`}>
            <img className="home-banner" src={PPLadvert} alt="PPL advert" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
