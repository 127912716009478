import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../../App";
import navigationNames from "../../utils/navigationNames";
import linkNames from "../../utils/linkNames";

const Nav: React.FC = () => {
  const { language } = useContext(LanguageContext);

  const [menuOpen, setMenuOpen] = useState(false);

  const navNames = navigationNames[language];
  const links = linkNames[language];

  return (
    <nav>
      <button className="menu-btn" onClick={() => setMenuOpen(!menuOpen)}>
      {menuOpen ? "✖" : "☰"}
      </button>
      <div className={`button-container ${menuOpen ? "open" : ""}`}>
        <div className="dropdown">
          <NavLink
            to={`${language}/${links.about}/${links.company}`}
            className={({ isActive }) =>
              isActive ? "dropbtn-active" : "dropbtn"
            }
          >
            {navNames.about}
          </NavLink>
          <div className="dropdown-content">
            <NavLink to={`${language}/${links.about}/${links.company}`}>{navNames.company}</NavLink>
            <NavLink to={`${language}/${links.about}/${links.team}`}>{navNames.team}</NavLink>
            <NavLink to={`${language}/${links.about}/${links.documents}`}>{navNames.documents}</NavLink>
            <NavLink to={`${language}/${links.about}/${links.services}`}>{navNames.services}</NavLink>
          </div>
        </div>
        <div className="dropdown">
          <NavLink
            to={`${language}/${links.courses}`}
            className={({ isActive }) =>
              isActive ? "dropbtn-active" : "dropbtn"
            }
          >
            {navNames.courses}
          </NavLink>
          <div className="dropdown-content">
            <NavLink to={`${language}/${links.courses}/tig`}>{navNames.tig}</NavLink>
            <NavLink to={`${language}/${links.courses}/mag`}>{navNames.mag}</NavLink>
            <NavLink to={`${language}/${links.courses}/mig`}>{navNames.mig}</NavLink>
            <NavLink to={`${language}/${links.courses}/mma`}>{navNames.mma}</NavLink>
            <NavLink to={`${language}/${links.courses}/${links.qualification}`}>
              {navNames.qualification}
            </NavLink>
            <NavLink to={`${language}/${links.courses}/${links.companies}`}>{navNames.companies}</NavLink>
            <NavLink to={`${language}/${links.courses}/${links.unemployed}`}>{navNames.unemployed}</NavLink>
            <NavLink to={`${language}/${links.courses}/${links.howToChoose}`}>
              {navNames.howToChoose}
            </NavLink>
          </div>
        </div>
        <div className="dropdown">
          <NavLink
            to={`${language}/${links.certification}`}
            className={({ isActive }) =>
              isActive ? "dropbtn-active" : "dropbtn"
            }
          >
            {navNames.sertification}
          </NavLink>
        </div>
        <div className="dropdown">
          <NavLink
            to={`${language}/${links.learner}`}
            className={({ isActive }) =>
              isActive ? "dropbtn-active" : "dropbtn"
            }
          >
            {navNames.learner}
          </NavLink>
        </div>
        <div className="dropdown">
          <NavLink
            to={`${language}/${links.faq}`}
            className={({ isActive }) =>
              isActive ? "dropbtn-active" : "dropbtn"
            }
          >
            {navNames.faq}
          </NavLink>
        </div>
        <div className="dropdown">
          <NavLink
            to={`${language}/${links.contacts}`}
            className={({ isActive }) =>
              isActive ? "dropbtn-active" : "dropbtn"
            }
          >
            {navNames.contacts}
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
